import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow, TableCell, TableBody, IconButton
} from '@mui/material';
import {Delete as DeleteIcon, Edit as EditIcon, Send as SendIcon} from "@mui/icons-material";

function SubmittedForms() {
    const [submittedForms, setSubmittedForms] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        fetch('https://qmemory.ru/api/job-safety-analysis', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                setSubmittedForms(data);
            })
            .catch(error => console.error('Error loading submitted forms:', error));
    }, []);

    return (
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Day/Date</TableCell>
                        <TableCell>Project Number</TableCell>
                        <TableCell>Project Name</TableCell>
                        <TableCell>Supervisor Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {submittedForms.map((form) => (
                        <TableRow key={form.id}>
                            <TableCell>{form.dayDate}</TableCell>
                            <TableCell>{form.project.projectNumber}</TableCell>
                            <TableCell>{form.project.projectName}</TableCell>
                            <TableCell>{form.project.supervisorName}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default SubmittedForms;
