import React, { useState, useEffect } from 'react';
import { TextField, Checkbox, FormControlLabel, Button, Container, Grid, Typography, Box, RadioGroup, FormControl, FormLabel, Radio } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

function EditForm() {
    const { id } = useParams(); // Получаем идентификатор формы из URL
    const navigate = useNavigate();
    const [formData, setFormData] = useState(null);

    // Загружаем форму из localStorage при монтировании компонента
    useEffect(() => {
        const savedForms = JSON.parse(localStorage.getItem('forms')) || [];
        const formToEdit = savedForms.find(form => form.id === parseInt(id));
        if (formToEdit) {
            setFormData(formToEdit);
        } else {
            alert('Form not found!');
            navigate('/saved');
        }
    }, [id, navigate]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const [section, field] = name.split('.');

        if (section === 'workActivities') {
            setFormData(prevState => ({
                ...prevState,
                workActivities: {
                    ...prevState.workActivities,
                    [field]: type === 'checkbox' ? checked : value,
                },
            }));
        } else if (section === 'evaluation') {
            setFormData(prevState => ({
                ...prevState,
                evaluation: {
                    ...prevState.evaluation,
                    [field]: value,
                },
            }));
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSave = () => {
        const savedForms = JSON.parse(localStorage.getItem('forms')) || [];
        const updatedForms = savedForms.map(form => (form.id === parseInt(id) ? formData : form));
        localStorage.setItem('forms', JSON.stringify(updatedForms));
        alert('Form updated!');
        navigate('/saved'); // Возвращаем пользователя к списку сохраненных форм
    };

    if (!formData) return <Typography>Loading...</Typography>;

    return (
        <Container style={{ margin: '100px auto', padding: '20px', border: "solid 1px black", borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Grid container>
                {/* Верхняя часть */}
                <Grid container style={{ marginBottom: '20px', padding: '10px' }}>
                    <Grid item xs={12} sm={3} style={{ padding: '10px' }}>
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <img src={"https://qmemory.ru/build/backend/images/logo.png"} width="160px" height="160px" alt="Logo" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ padding: '10px' }}>
                        <TextField
                            label="Day/Date"
                            type="date"
                            name="dayDate"
                            value={formData.dayDate}
                            onChange={handleChange}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            style={{ marginBottom: '10px' }}
                        />
                        <TextField
                            label="Project Number"
                            name="projectNumber"
                            value={formData.projectNumber}
                            onChange={handleChange}
                            fullWidth
                            style={{ marginBottom: '10px' }}
                        />
                        <TextField
                            label="Project Name"
                            name="projectName"
                            value={formData.projectName}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                        <Typography variant="h6" align="center">
                            D.H. Griffin <br /> Companies <br /> Job Safety Analysis (JSA)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ padding: '10px' }}>
                        <TextField
                            label="DHG Company/Sub Name"
                            name="dhgCompany"
                            value={formData.dhgCompany}
                            onChange={handleChange}
                            fullWidth
                            style={{ marginBottom: '10px' }}
                        />
                        <TextField
                            label="Supervisor Name"
                            name="supervisorName"
                            value={formData.supervisorName}
                            onChange={handleChange}
                            fullWidth
                            style={{ marginBottom: '10px' }}
                        />
                        <TextField
                            label="Task Location"
                            name="taskLocation"
                            value={formData.taskLocation}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                {/* WORK ACTIVITIES Section */}
                <Grid item xs={12} style={{ border: '1px solid #000', padding: 0, marginBottom: '20px' }}>
                    <Typography variant="h6" style={{ backgroundColor: '#000', color: '#fff', padding: '5px' }}>Work Activities</Typography>
                    <Grid container style={{ padding: '20px' }}>
                        {Object.keys(formData.workActivities).map((activity, index) => (
                            activity !== 'customActivity' && (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name={`workActivities.${activity}`}
                                                checked={formData.workActivities[activity]}
                                                onChange={handleChange}
                                            />
                                        }
                                        label={activity.replace(/([A-Z])/g, ' $1').trim()}
                                    />
                                </Grid>
                            )
                        ))}
                        <Grid item xs={12}>
                            <TextField
                                label="Other (Describe)"
                                name="workActivities.customActivity"
                                value={formData.workActivities.customActivity}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {/* Evaluation of Work Area Section */}
                <Grid item xs={12} style={{ border: '1px solid #000', padding: '0', marginBottom: '20px' }}>
                    <Typography variant="h6" style={{ backgroundColor: '#000', color: '#fff', padding: '5px', }}>Evaluation of Work Area</Typography>
                    <Grid container style={{ padding: '20px' }}>
                        {Object.keys(formData.evaluation).map((evaluation, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{evaluation.replace(/([A-Z])/g, ' $1').trim()}</FormLabel>
                                    <RadioGroup
                                        name={`evaluation.${evaluation}`}
                                        value={formData.evaluation[evaluation]}
                                        onChange={handleChange}
                                        row
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="na" control={<Radio />} label="N/A" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                {/* Save Changes Button */}
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
                        Save Changes
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}

export default EditForm;
