import React, { useEffect, useState } from 'react';
import { Route, Routes, Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container } from '@mui/material';
import JobSafetyAnalysisForm from './JobSafetyAnalysisForm';
import SavedForms from './SavedForms';
import SubmittedForms from './SubmittedForms';
import EditForm from './EditForm';
import LoginPage from './LoginPage';

function App() {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // Проверяем, авторизован ли пользователь
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
            navigate('/login'); // Перенаправляем на страницу логина, если нет токена
        }
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('token'); // Удаляем токен
        setIsAuthenticated(false);
        navigate('/login'); // Перенаправляем на страницу логина
    };

    return (
        <>
            {isAuthenticated && (
                <AppBar position="fixed">
                    <Toolbar>
                        <Typography variant="h6" style={{ flexGrow: 1 }}>
                            JSA Application
                        </Typography>
                        <Button color="inherit" component={Link} to="/">Create New Form</Button>
                        <Button color="inherit" component={Link} to="/saved">Saved Forms</Button>
                        <Button color="inherit" component={Link} to="/submitted">Submitted Forms</Button>
                        <Button color="inherit" onClick={handleLogout}>Logout</Button>
                    </Toolbar>
                </AppBar>
            )}

            {/* Отступ для содержимого */}
            <Container style={{ marginTop: isAuthenticated ? '80px' : '0' }}>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    {isAuthenticated ? (
                        <>
                            <Route path="/" element={<JobSafetyAnalysisForm />} />
                            <Route path="/saved" element={<SavedForms />} />
                            <Route path="/submitted" element={<SubmittedForms />} />
                            <Route path="/edit/:id" element={<EditForm />} />
                        </>
                    ) : (
                        <Route path="*" element={<LoginPage />} />
                    )}
                </Routes>
            </Container>
        </>
    );
}

export default App;
